import { Card, Col, Row } from "antd";
import { Link } from "gatsby";
import React from "react";
import ReactMarkdown from "react-markdown";
import SectionWithBackground from "../SectionWithBackground";
import "./three-columns-styles.less";

const isLocalLink = (lnk) => lnk[0] === "/";

const HoverableCard = ({ title, column_image, column_content, hoverable }) => (
	<Card
		title={title}
		hoverable={hoverable}
		cover={column_image && <img src={column_image} alt={title} />}
	>
		<ReactMarkdown>{column_content}</ReactMarkdown>
	</Card>
);

const LinkableCard = ({ link, column_title, ...props }) => {
	return link ? ( // is there a link ?
		isLocalLink(link) ? ( // is it local ? => use Gatsby
			<Link to={link} title={column_title}>
				<HoverableCard
					hoverable={true}
					title={column_title}
					{...props}
				/>
			</Link>
		) : (
			// is it absolute => use anchor
			<a href={link} title={column_title}>
				<HoverableCard
					hoverable={true}
					title={column_title}
					{...props}
				/>
			</a>
		)
	) : (
		// no link => not hoverable
		<HoverableCard hoverable={false} title={column_title} {...props} />
	);
};

const ThreeColumns = ({ title, columns, paragraph, ...background }) => (
	<SectionWithBackground section_name="three-columns" {...background}>
		<div className="container">
			<h2 className="three-columns-title">{title}</h2>
			{paragraph && <ReactMarkdown>{paragraph + "---"}</ReactMarkdown>}

			<Row gutter={36} justify="center">
				{columns.map((col) => (
					<Col span={24} md={8}>
						<LinkableCard {...col} />
					</Col>
				))}
			</Row>
		</div>
	</SectionWithBackground>
);

export default ThreeColumns;
